import {initializeApp} from 'firebase/app';
import 'firebase/firestore';
import {deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytes, uploadString} from 'firebase/storage'
import {collection, doc, getDoc, getDocs, getFirestore, query, setDoc, where} from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app)


export const sendApplicantObject = async (applicant: any) => {
    const docRef = doc(db, "root_applicant", `${applicant.email} - ${applicant.appDate}` );
    return await setDoc(docRef, applicant)
}

export const getData = async (applicant: any) => {
    const docRef = doc(db, 'root_applicant', applicant.email);
    const docSnap = await getDoc(docRef)
    console.log(docSnap.data())
}

export const getVisaData = async (id: string) => {
    const docRef = query(collection(db, 'root_applicant'), where('uid', '==', id));
    const docSnap = await getDocs(docRef);
    let res;

    if (!docSnap.empty) {
        docSnap.forEach(el => {
            res = el.data()
            // breaks forEach after first el
            return
        })

        // returns visa-s data
        return res
    }
}

export const getAllVisas = async () => {
    const docRef = collection(db, 'root_applicant');
    const docSnap = await getDocs(docRef);
    let res: any = []
    docSnap.forEach(el => {
        res = [...res, el.data()]
    })
    return res
}

export const fileUpload = async (folderName: string, file: File) => {
    const imagesRef = ref(storage, `${folderName}/${file.name}`);
    let snapshot = await uploadBytes(imagesRef, file)

    // returns URL of file
    return await getDownloadURL(snapshot.ref)
}

export const base64Upload = async (folderName: string, base64: string, fileName: string) => {
    const imagesRef = ref(storage, `${folderName}/${fileName}`);
    let snapshot = await uploadString(imagesRef, base64, 'data_url')
    return await getDownloadURL(snapshot.ref)
}

export const listAllImagesFromStore = () => {
    const listRef = ref(storage, 'ticket1201@gmail.com');

    // Find all the prefixes and items.
    listAll(listRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                // console.log(itemRef.name)
                getDownloadURL(itemRef).then(res => console.log(res))
                console.log(itemRef.fullPath)
                // All the items under listRef.
            });
        })
        .catch((error) => {
            console.log('oops')
            // Uh-oh, an error occurred!
        })
        .finally(() => console.log('finish'));
}


export const delFile = (name: string) => {
    const desertRef = ref(storage, 'contactFrom/' + name);

    // Delete the file
    deleteObject(desertRef).then(() => {
        console.log('deleted')
    })

}

export const getIndividTourDates = async () => {
    const docRef = collection(db, 'city_tours_dates');
    const docSnap = await getDocs(docRef);
    let res: any = [];
    docSnap.forEach((el) => {
        res = [...res, el.data()];
    });
    return res[0];
}

