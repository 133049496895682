import {AnyAction, combineReducers, configureStore, ThunkAction, ThunkDispatch} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {visaCheckReducer} from './visaCheckReducer';
import {visaPageReducer} from "./visaPageReducer";
import {appReducer} from './appReducer';
import  { blogsReducer } from "./blogsSlice";

const rootReducer = combineReducers({
    app: appReducer,
    visa_check: visaCheckReducer,
    visa_page: visaPageReducer,
    blogsSlice: blogsReducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }).prepend(thunk)
})

export type RootActionsType = AnyAction

export type RootStateType = ReturnType<typeof store.getState>
export type RootDispatchType = ThunkDispatch<RootStateType, unknown, RootActionsType>
export type RootThunkType<ReturnType = void> = ThunkAction<ReturnType, RootStateType, unknown, RootActionsType>