import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getVisaData} from '../api/firebaseAPI';
import {setAppStatusAC, setMessageTC} from './appReducer';
import {ApplicantDataType, clearState, VisaPageDataType, visaStatus} from './visaPageReducer';

const slice = createSlice({
    name: 'visa_check',
    initialState: {
        service: undefined,
        email: undefined,
        tel: undefined,
        numberOfApplicants: undefined,
        visa_status: '',
        file: null,
        applicantsData: [],
        additionalService:{}
    } as visaCheckStateType,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getVisaTC.fulfilled, (state:visaCheckStateType, action) => {
                state.service = action?.payload?.service
                state.numberOfApplicants = action?.payload?.numberOfApplicants
                state.visa_status = action?.payload?.visa_status || ''
                state.tel = action?.payload?.tel
                state.file = action?.payload?.file
                state.additionalService = action?.payload?.additionalService || {}
            })
            .addCase(clearState, (state:visaCheckStateType) => {
                state.service = undefined
                state.email = undefined
                state.tel = undefined
                state.numberOfApplicants = undefined
                state.visa_status = ''
                state.file = null
                state.applicantsData = []
                state.additionalService = {}
            })
    }
})

export const getVisaTC = createAsyncThunk('visa_check/fetchVisa', async (id: string, {dispatch, rejectWithValue}) => {
    dispatch(setAppStatusAC({status: 'loading'}))
    try {
        let res = await getVisaData(id) as VisaPageDataType | undefined
        if (!res) {
            dispatch(setMessageTC({message: 'Неверный номер', isError: true}))
        }
        return res
    } catch {
        return rejectWithValue(null)
    } finally {
        dispatch(setAppStatusAC({status: 'idle'}))
    }
})


export const visaCheckReducer = slice.reducer;

export type visaCheckStateType = {
    service: null | string | undefined
    email: null | string | undefined
    tel: null | string | undefined
    numberOfApplicants: null | string | undefined
    visa_status: visaStatus | ''
    file: null | Array<string> | undefined
    applicantsData: Array<ApplicantDataType>
    additionalService: {
        hotel?: number;
        hotelData?: Array<{
            hotelName: string;
            bookingRef: string;
            issuedDate: string;
            settleInDate: string;
            settleOutDate: string;
            bookingName: string;
            roomType: string;
            hotelAddress: string;
            contactTel: string;
            file: string;
        }>;
        arrivalCard?: number;
        arrivalCardData?: Array<string>;
        insurance?: number;
        insuranceData?: Array<string>;
    };
}
