import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBlogsData } from "../api/api";
import { BlogType } from "../data/types";

export const fetchBlogsDataTC = createAsyncThunk(
  'blogs/fetchData',
  async (
    _,
    { dispatch, rejectWithValue }) => {
    try {
      return await getBlogsData();
    } catch {
      return rejectWithValue(null);
    }
  }
);

const blogsSlice = createSlice({
  name: 'blogs',
  initialState: [] as InitialStateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogsDataTC.fulfilled, (state, action) => {
        return action.payload
      })
  }
})

export type InitialStateType = Array<BlogType>

export const blogsReducer = blogsSlice.reducer