import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {mailJsAPI} from '../api/api';
import {fileUpload, getIndividTourDates} from '../api/firebaseAPI';
import moment, {Moment} from "moment/moment";
import {GuestsObject} from "../components/HeroSearchForm2Mobile/GuestsInput";

export const setMessageTC = createAsyncThunk('app/setMessage', (params: {
  message: string,
  isError: boolean
}, {dispatch}) => {
  if (params.isError) {
    dispatch(setErrorMessageAC({message: params.message}))
  } else {
    dispatch(setAppMessageAC({message: params.message}))
  }
  setTimeout(() => {
    dispatch(setAppMessageAC({message: ''}))
    dispatch(setErrorMessageAC({message: ''}))
  }, 2000)
})

export const contactSendMessageTC = createAsyncThunk('app/contactMessageSend', async (params: {
  data: { email: string, name: string, tel: string, text: string },
  files: File[]
}, thunkAPI) => {
  let {dispatch} = thunkAPI
  let {data, files} = params

  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    let urls: Array<string> = []
    for (let i = 0; i < files.length; i++) {
      let res = await fileUpload('contactFrom', files[i])
      urls = [...urls, '    ', res]
    }
    await mailJsAPI.sendFromContactForm({...data, files: urls})
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})
export const checkoutServiceMessageTC = createAsyncThunk('app/checkoutServiceMessage', async (params: {
  email: string,
  name: string,
  tel: string,
  text: string
} & serviceType, {dispatch}) => {
  dispatch(setAppStatusAC({status: 'loading'}))
  try {
    let guests = params.guests.guestAdults! + params.guests.guestChildren! + params.guests.guestInfants!
    await mailJsAPI.sendServiceRequest({...params, date: moment(params.date).format('DD.MM.YYYY'), guests})
  } finally {
    dispatch(setAppStatusAC({status: 'idle'}))
  }
})

export const fetchAvailableTourDatesTC = createAsyncThunk('app/fetchAvailableTourDates', async (_, thunkAPI) => {
  try {
    let res:{dates:Array<string>} =  await getIndividTourDates()
    return res.dates
  } catch {
    return thunkAPI.rejectWithValue(null)
  }
})


const slice = createSlice({
  name: 'app',
  initialState: {
    appStatus: 'idle',
    appMessage: '',
    errorMessage: '',
    availableTourDates: [],
    service: {
      date: null,
      service_name: '',
      guests: {
        guestAdults: 1,
        guestChildren: 0,
        guestInfants: 0,
      }
    }
  } as AppStateType,
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableTourDatesTC.fulfilled, (state, action) => {
      state.availableTourDates = action.payload
    })
  },
  reducers: {
    setAppStatusAC(state, action: PayloadAction<{ status: appStatusType }>) {
      state.appStatus = action.payload.status
    },
    setAppMessageAC(state, action: PayloadAction<{ message: string }>) {
      state.appMessage = action.payload.message
    },
    setErrorMessageAC(state, action: PayloadAction<{ message: string }>) {
      state.errorMessage = action.payload.message
    },
    setServiceAC(state, action: PayloadAction<serviceType>) {
      state.service = action.payload
    },
    setServiceFieldsAC(state, action: PayloadAction<Partial<serviceType>>) {
      state.service = {...state.service, ...action.payload}
    }
  },
})


export const appReducer = slice.reducer;
export const {
  setAppStatusAC, setAppMessageAC, setErrorMessageAC, setServiceAC,
  setServiceFieldsAC
} = slice.actions;

export type AppStateType = {
  appStatus: appStatusType
  appMessage: string
  errorMessage: string
  service: serviceType
  availableTourDates: Array<string>
}

export type serviceType = {
  date: Moment | null
  service_name: string
  guests: GuestsObject
}
export type appStatusType = 'loading' | 'idle'